import React from "react"
import Fade from 'react-reveal/Fade';
import Link from '../utils/link'

import SEO from "../components/seo"

const HomePage = () => (
  <>
    <SEO title="Home" />
    <section className='intro'>
    	<div className='intro__inner'>
    		<div className='intro__header'>
          <div className='intro__sticky'>
            <Fade>
              <h1>Matthew Pringle</h1>
              <h2>Your Strategic,<br /> Commercial Advisor.</h2>
              <h5>Contact Details</h5>
              <ul>
                <li><Link to='mailto:mpringle@netspace.net.au'>Email Matthew</Link></li>
                <li><Link to='tel:+61400111222'>+61 400  111 222</Link></li>
                <li></li>
              </ul>
            </Fade>
          </div>
			  </div>
        <div className='intro__content'>
          <picture>
            <Fade>
              <img src={require('../assets/images/matt-pringle-1.jpg')} alt='Math Pringle' />
            </Fade>
          </picture>
          <Fade>
            <h3>Supporting<br /> clients through<br /> demanding<br /> times</h3>
            <h5>I bring a broad capability combined with deep technical expertise and practical experience to achieve the clarity and confidence required in making critical commercial decisions.</h5>
            <p>My experience spans decades in which I've advised in boom times as well as numerous cyclical downturns, market-driven panics and one-off crisis events.  I've also seen how technology, innovation and global trade have reshaped our business world.</p>
            <p>Disruption, which is familiar and constant, presents an opportunity for businesses to reset. How a business responds to headwinds will either accelerate growth and wealth creation or be the reason it doesn't achieve its potential.</p>
            <p>As a former senior partner with Melbourne's largest mid-tier accounting and professional advisory firm, I draw on experience across industries and sectors to partner with clients in making robust commercial decisions and achieving results. </p>
          </Fade>
        </div>
		  </div>
		</section>

    <section className='content'>
      <div className='content__inner'>
        <div className='content__image'>
          <picture>
            <Fade>
              <img src={require('../assets/images/matt-pringle-2.jpg')} alt='Math Pringle' />
            </Fade>
          </picture>
          <Fade>
            <h4>I help businesses protect  their commercial position,  grow market share and  transition successfully.</h4>
          </Fade>
        </div>
        <div className='content__text'>
          <Fade>
            <p>I provide expert counsel and professional support through restructures and refinancings, pivots and turnaround, mergers and acquisitions, and succession plans and exits. Proudly, I can say I've helped launch and grow businesses, and been instrumental in successful turnarounds and saving livelihoods.</p>
            <p>My experience spans decades in which I've advised in boom times as well as numerous cyclical downturns, market-driven panics and one-off crisis events.  I've also seen how technology, innovation and global trade have reshaped our business world. </p>
            <p>Disruption, which is familiar and constant, presents an opportunity for businesses to reset. How a business responds to headwinds will either accelerate growth and wealth creation or be the reason it doesn't achieve its potential. </p>
            <p>As a former senior partner with Melbourne's largest mid-tier accounting and professional advisory firm, I draw on experience across industries and sectors to partner with clients in making robust commercial decisions and achieving results. </p>
          </Fade>
        </div>
      </div>
    </section>

    <section className='thirds'>
      <div className='thirds__header'>
        <h4>Protect</h4>
        <h4>Grow</h4>
        <h4>Transition</h4>
      </div>
      <div className='thirds__inner'>
        <Fade>
          <ul>
            <li><span>Restructures and turnaround</span></li>
            <li><span>Distressed balance sheets</span></li>
            <li><span>Situation analysis and available options</span></li>
            <li><span>Litigation support and forensic investigations</span></li>
          </ul>
          <ul>
            <li><span>Restructures and turnaround</span></li>
            <li><span>Distressed balance sheets</span></li>
            <li><span>Situation analysis and available options</span></li>
            <li><span>Litigation support and forensic investigations</span></li>
          </ul>
          <ul>
            <li><span>Restructures and turnaround</span></li>
            <li><span>Distressed balance sheets</span></li>
            <li><span>Situation analysis and available options</span></li>
            <li><span>Litigation support and forensic investigations</span></li>
          </ul>
        </Fade>
      </div>
    </section>

    <section className='approach'>
      <div className='approach__inner'>
        <Fade>
          <h3>A bespoke approach</h3>
        </Fade>
        <div className='approach__list'>
          <Fade>
            <div className='approach__item'>
              <h4>Strategic Inquiry</h4>
              <p>A critical assessment of the business structure and plan, and its people's skills and capacity to deliver growth outcomes.</p>
            </div>
            <div className='approach__item'>
              <h4>Strategic Inquiry</h4>
              <p>A critical assessment of the business structure and plan, and its people's skills and capacity to deliver growth outcomes.</p>
            </div>
            <div className='approach__item'>
              <h4>Strategic Inquiry</h4>
              <p>A critical assessment of the business structure and plan, and its people's skills and capacity to deliver growth outcomes.</p>
            </div>
            <div className='approach__item'>
              <h4>Strategic Inquiry</h4>
              <ul>
                <li>Factual understanding of the present state</li>
                <li>Realising possible future states</li>
                <li>Recognising individual and organisational drivers and inhibitors</li>
                <li>A clear vision for growth</li>
              </ul>
            </div>
            <div className='approach__item'>
              <h4>Strategic Inquiry</h4>
              <ul>
                <li>Factual understanding of the present state</li>
                <li>Realising possible future states</li>
                <li>Recognising individual and organisational drivers and inhibitors</li>
                <li>A clear vision for growth</li>
              </ul>
            </div>
            <div className='approach__item'>
              <h4>Strategic Inquiry</h4>
              <ul>
                <li>Factual understanding of the present state</li>
                <li>Realising possible future states</li>
                <li>Recognising individual and organisational drivers and inhibitors</li>
                <li>A clear vision for growth</li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    </section>

    <section className='career'>
      <div className='career__inner'>
        <div className='career__text'>
          <Fade>
            <h3>Professional Career</h3>
            <p>My expertise draws upon 40 years of experience in the critical areas of accounting, audit and assurance, capital markets, business finance and development, growth strategy, succession, corporate governance, and board advisory. I have continually maintained and advanced my skills and knowledge during that time.</p>
            <p>I have experience across the spectrum, from start-ups through to major listed national and international firms. From designing and facilitating complex M&A deals through to guiding businesses through sensitive regulatory compliance issues, I’ve always been focused on ensuring the best possible outcomes for key stakeholders.</p>
            <p>My cumulative, in-depth technical knowledge enables me to provide a comprehensive response to a wide range of financial and governance issues. I bring substantial experience in dealing with regulators and smoothing the path to compliance through better financial reporting and corporate governance practices.</p>
          </Fade>
        </div>
        <picture>
          <Fade>
            <img src={require('../assets/images/matt-pringle-3.jpg')} alt='Math Pringle' />
          </Fade>
        </picture>
      </div>
    </section>

    <section className='services'>
      <div className='services__inner'>
        <Fade>
          <h5>I bring a broad capability combined with deep technical expertise and practical experience to achieve the clarity and confidence required in making critical commercial decisions. </h5>
        </Fade>

        <div className='services__list'>
          <Fade>
            <div className='services__item'>
              <h3>Commercial Advice</h3>
              <p>Drawing on deep finance and commercial expertise, I partner with business owners and senior management to set strategy and plans to optimise business performance and outcomes.</p>
              <p>Executives, peers and other professionals regularly seek my advice as an independent expert on commercial and technical matters. I provide robust and pragmatic advice that acknowledges and respects each situation as unique. Experienced in assisting many organisations to navigate complex and volatile circumstances, I strive to ensure the outcome is fair and optimal for all involved.</p>
            </div>

            <div className='services__item'>
              <h3>Commercial Advice</h3>
              <p>Drawing on deep finance and commercial expertise, I partner with business owners and senior management to set strategy and plans to optimise business performance and outcomes.</p>
              <p>Executives, peers and other professionals regularly seek my advice as an independent expert on commercial and technical matters. I provide robust and pragmatic advice that acknowledges and respects each situation as unique. Experienced in assisting many organisations to navigate complex and volatile circumstances, I strive to ensure the outcome is fair and optimal for all involved.</p>
            </div>

            <div className='services__item'>
              <h3>Commercial Advice</h3>
              <p>Drawing on deep finance and commercial expertise, I partner with business owners and senior management to set strategy and plans to optimise business performance and outcomes.</p>
              <p>Executives, peers and other professionals regularly seek my advice as an independent expert on commercial and technical matters. I provide robust and pragmatic advice that acknowledges and respects each situation as unique. Experienced in assisting many organisations to navigate complex and volatile circumstances, I strive to ensure the outcome is fair and optimal for all involved.</p>
            </div>

            <div className='services__item'>
              <h3>Commercial Advice</h3>
              <p>Drawing on deep finance and commercial expertise, I partner with business owners and senior management to set strategy and plans to optimise business performance and outcomes.</p>
              <p>Executives, peers and other professionals regularly seek my advice as an independent expert on commercial and technical matters. I provide robust and pragmatic advice that acknowledges and respects each situation as unique. Experienced in assisting many organisations to navigate complex and volatile circumstances, I strive to ensure the outcome is fair and optimal for all involved.</p>
            </div>
          </Fade>
        </div>
      </div>
    </section>

  </>
)

export default HomePage
